import React from "react"
import { Link } from 'src/components/Common';
import { URL } from '../url';

export const HE = {
  section1: {
    p: [
      "במהלך השנים האחרונות בית התוכנה אפספורס הוביל ופיתח בהצלחה רבה מגוון פתרונות ואפליקציות SaaS לחברות ולארגונים ממגזרים שונים. צוות הפיתוח של החברה מציין כי יש מגמת מעבר לאפליקציות SaaS מאחר וניתן להציע את המוצר הדיגיטלי כשירות בענן. צוות הפיתוח שלנו משתמש בטכנולוגיות ומתודולוגיות המתאימות ביותר לאפליקציה ולצרכי הלקוח, הצוות יתווה את הארכיטקטורה וילווה אתכם לאורך כל תהליך הפיתוח וההטמעה."
    ],
  },
  section2: {
    h: "מה זה SaaS?",
    p: [
      "SaaS היא תוכנה כשירות (SaaS - Software as a Service) הנמצאת על אתר הספק וכוללת תהליכים לקבלת שירות מסוים, כאשר השירות ניתן על בסיס מנוי וללא צורך בהתקנה מקומית. תוכנה כשירות מסופקת למשתמשים וכוללת את כל התהליכים הנדרשים לקבלת שירות מסוים, התוכנה ממוקמת על שרת של הספק. למעשה, זהו המודל הנפוץ ביותר בתחום של מחשוב ענן ויש הרואים במודל זה כמיקור חוץ ליישומים טכנולוגיים פופולריים כדוגמת דרופבוקס, אופיס 365 וכיוצא בזה.",
      "כדאי לדעת כי חברות הטכנולוגיה והשירותים הפיננסים הובילו את אימוץ מודל ה- SaaS, וכיום המודל נפוץ בארגונים השונים בעיקר לצורך ניהול משאבי אנוש, רכש, פיננסים, ניהול קשרי לקוחות, שימור לקוחות וכדומה, ותוכנה כשירות משמשת בין היתר לאחסון קבצים ותמונות כמו בגוגל דרייב, לצריכת תוכן אונליין כמו בשירות נטפליקס, למערכת להפקת חשבוניות אונליין ועוד.",
      <>
        {`המעבר לשירותי ענן צבר תאוצה בשנים האחרונות וכולל גם שימוש מוגבר בתוכנה כשירות, המודל מאפשר לארגונים השונים לקבל שירותי תוכנה באמצעות רשת תקשורת וחיבור לאתר הספק, וכך הארגון אינו צריך לרכוש תוכנה ולהטמיע אותה במערכת המחשוב של הארגון עצמו. במודל זה הארגון חייב להסתמך על שירותים חיצוניים לתפעול ולתחזוקה של נתוניו הפיננסיים והתפעוליים אשר מנוהלים על ידי תוכנה כשירות, לכן חשוב להבין את הסיכונים הכרוכים בכך ומדוע כדאי לבחור בנו לצורך `}
        <Link to={URL.SAAS_CONSULTING_SERVICE} active>ייעוץ ותכנון אפליקציות Saas.</Link>
      </>
    ],
  },
  section3: {
    h: "דגשים חשובים בעת תכנון אפליקציית SaaS",
    subH: "1. ייעוץ לקראת מעבר למודל תוכנה כשירות",
    p: [
      "מקבלי ההחלטות בכל חברה ובכל ארגון המזהים צורך עסקי ושוקלים מעבר ל- SaaS חייבים לקבל ייעוץ מקצועי כבר בשלב זה כדי לנתח את צרכי העסק ולאפיין את האתגרים של המעבר למודל החדש, ההערכה כוללת צפי עלויות, לוח זמנים, היתכנות כלכלית ועוד, ורק לאחר ייעוץ מקצועי ההנהלה של הארגון יכולה להחליט האם המעבר כדאי ומשתלם וכיצד לבצעו נכון.",
    ]
  },
  section4: {
    subH: "2. בחירת בית תוכנה",
    p: [
      <>
        {`בעת תכנון אפליקציית SaaS יש חשיבות רבה ל`}
        <Link to={URL.HOME} active>בחירה נכונה של בית התוכנה ואנשי הפיתוח</Link>.
        {` חשוב לזכור כי פיתוח אפליקציות במודל התוכנה כשירות דורש משאבים עצומים מהלקוח, ויש לקבל החלטות רבות כדי להתוות את הדרך ועל מנת לפתח מוצר ייעודי העונה על צרכי הארגון ומטרותיו העסקיות. בחירה נכונה של בית תוכנה על ידי מקבלי ההחלטות וההנהלה הבכירה מבטיחה פתרון מקצועי ומותאם אישית, תוך חיסכון בעלויות ופתרונות מתקדמים המבטיחים החזר השקעה בפרק זמן יחסית קצר.`}
      </>
    ]
  },
  section5: {
    subH: "3. מדדים להחזר השקעה",
    p: [
      "רוב העסקים המציעים פתרונות המבוססים על אפליקציות SaaS מתמודדים בסביבה מאוד תחרותית, ובמצבים מסוימים הם מציעים פתרונות דומים למתחרים לכן יש תחרות עצומה על הלקוחות הפוטנציאליים. בעת תכנון האפליקציה יש להתחיל עם מחקר שוק ומתחרים ולשים לב לאתגרים איתם הלקוח מתמודד בסביבה העסקית בה הוא פועל. בשלב התכנון של התוכנה כשירות יש להתייחס נכון לשני מדדים חשובים וקריטיים למדידת החזר ההשקעה, המדדים הם:",
      "המטרה היא להחזיר את ההשקעה בגיוס הלקוחות בזמן הקצר ביותר, ושימוש מושכל ונכון במדדים אלו החל משלב התכנון מאפשר ללקוח לשפר את מאמציו השיווקיים כדי להשיג יותר לידים. בשלב התכנון של האפליקציה יש להתייחס למושגים נוספים כמו MMR המשקף את ההכנסה החודשית הקבועה, ARR המתאר את ההכנסה השנתית הצפויה, ARPU המשקף את הרווח הממוצע מכל יוזר הרוכש מנוי או חבילה מסוימת. אלו מדדים חשובים בכל תוכנה כשירות ויש להתייחס אליהם בשלב הייעוץ על מנת להתוות את ארכיטקטורת הפיתוח."
    ],
    li: [
      <>
        <strong>LTV</strong>
        {` – ראשי תיבות של Lifetime Value of a Typical customer, כלומר מה הערך הכולל שכל לקוח צפוי להניב עבור הארגון. `}
      </>,
      <>
        <strong>CAC</strong>
        {` – ראשי תיבות של Cost to Acquire a typical Customer, כלומר מהי עלות הרכישה והאחזקה עבור לקוח חדש.`}
      </>
    ],
  },
  section6: {
    subH: "4. עיצוב וחוויית הלקוח",
    p: [
      <>
        {`יש לתת דגש רב על נושא `}
        <Link to={URL.UI_UX_DESIGN_SERVICE} active>עיצוב הממשקים וחוויית המשתמש</Link>,
        {` בעיקר באפליקציות של מוצרים הנמכרים במודל התוכנה כשירות. חוויית הרכישה היא קריטית להצלחת המוצר או השירות, ואם מעצבים נכון את המערכת ודואגים כבר בשלב התכנון לחוויית משתמש נוחה, ברורה וייחודית – משפרים את חוויית הרכישה והשימוש, ויש לכך השפעה ישירה על הכנסות הארגון.`}
      </>
    ]
  },
  section7: {
    subH: "5. אבטחת מידע",
    p: [
      "בשלב האפיון ותכנון האפליקציה יש להתייחס לנושא אבטחת המידע. ראוי לציין כי האפליקציה ונתוני הארגון המשתמש בתוכנה כשירות מותקנים בשרת חיצוני לכן המידע עשוי להיות חשוף לעובדי ספק שירותי הענן ולספק התוכנה, וקיימים סיכוני אבטחת מידע נוספים בגלל המעבר לשירותי ענן. יש לתת לנושא זה עדיפות עליונה כבר בשלב התכנון כדי למנוע פרצות אבטחה וכדי לשמור ולהגן על הנתונים.",
    ]
  },
  section8: {
    subH: "6. התאמה אישית לצרכי הארגון",
    p: [
      "כאשר מתכננים מוצר תוכנה ייעודי במודל התוכנה כשירות יש לבצע התאמות ייחודיות על פי צרכי הלקוח ומטרותיו העסקיות. קל יותר לבצע התאמות למוצר תוכנה המותקן במערכת המחשוב של הלקוח, לכן אין להתפשר בבחירת בית תוכנה ויש לוודא כי המוצר מותאם באופן מלא ומושלם למטרות וליעדים שהארגון רוצה להשיג.",
    ]
  },
  section9: {
    subH: "7. תכנון מרחב עבודה דיגיטלי",
    p: [
      "בשלב תכנון האפליקציה החדשה המבוססת על מודל ה- SaaS יש לשים לב גם למרחב העבודה הדיגיטלי של הצוותים השונים, לכן חשוב לכלול במערכת או במוצר פתרונות יעילים לעבודת צוות נוחה ומתקדמת כמו צ'אט, מערכת לשיתוף קבצים, ממשקי Web ועוד.",
    ]
  },
};