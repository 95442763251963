import * as React from "react";
import { graphql } from 'gatsby';
import Blog, { BlogSubHeader, BlogList, BlogListItem, BlogSubMiniHeader, BlogUnorderList, BlogUnorderListItem } from '../../components/Blog';
import { Paragraph } from "../../components/Common";
import { HE } from '../../blogs/principles_on_designing_saas_app';
import { ReactVsAngular, BenefitsOfWorkingWithSoftwareCompany, SolutionsForSoftwareDevelopment, EmphasisToConsiderWhenDesigningSaaSApp } from '../../blogs';
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { BLOGS_ORIGINAL_BREADCRUMBS } from '../../constants';
import { useArticelSchema } from '../../hooks/useArticelSchema';

const title = 'Emphasis to consider when designing saas app';

const articleSchemaData = {
  headline: title,
  description: "SEO description principles on designing saas app",
  image: "https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fprinciples-on-designing-saas-app.jpg?alt=media&token=2072e521-7673-48a3-86d0-9b5b6a95f6a5",
  datePublished: '2022-03-05'
};

const PrinciplesOnDesigningSaasApp = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema(title, BLOGS_ORIGINAL_BREADCRUMBS);
  const articelSchema = useArticelSchema(articleSchemaData);

  return (
    <Blog
      url={EmphasisToConsiderWhenDesigningSaaSApp.url}
      banner="/saas-principles/banner.jpg"
      relatedBlogs={[BenefitsOfWorkingWithSoftwareCompany, ReactVsAngular, SolutionsForSoftwareDevelopment]}
      title={title}
      seo={{
        title: 'SEO title principles on designing saas app',
        description: 'SEO description principles on designing saas app',
        image: "https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fprinciples-on-designing-saas-app.jpg?alt=media&token=2072e521-7673-48a3-86d0-9b5b6a95f6a5",
        breadcrumbsElementsSchema,
        articelSchema,
      }}
    >
      {HE.section1.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section2.h}
      </BlogSubHeader>
      {HE.section2.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section3.h}
      </BlogSubHeader>

      <BlogList>

        <BlogListItem>
          <BlogSubMiniHeader>
            {HE.section3.subH}
          </BlogSubMiniHeader>
          {HE.section3.p.map(p => <Paragraph>{p}</Paragraph>)}
        </BlogListItem>

        <BlogListItem>
          <BlogSubMiniHeader>
            {HE.section4.subH}
          </BlogSubMiniHeader>
          {HE.section4.p.map(p => <Paragraph>{p}</Paragraph>)}
        </BlogListItem>

        <BlogListItem>
          <BlogSubMiniHeader>
            {HE.section5.subH}
          </BlogSubMiniHeader>
          <Paragraph>
            {HE.section5.p[0]}
            <BlogUnorderList>
              {HE.section5.li.map(li => <BlogUnorderListItem>{li}</BlogUnorderListItem>)}
            </BlogUnorderList>
          </Paragraph>

          <Paragraph>
            {HE.section5.p[1]}
          </Paragraph>
        </BlogListItem>

        <BlogListItem>
          <BlogSubMiniHeader>
            {HE.section6.subH}
          </BlogSubMiniHeader>
          {HE.section6.p.map(p => <Paragraph>{p}</Paragraph>)}
        </BlogListItem>

        <BlogListItem>
          <BlogSubMiniHeader>
            {HE.section7.subH}
          </BlogSubMiniHeader>
          {HE.section7.p.map(p => <Paragraph>{p}</Paragraph>)}
        </BlogListItem>

        <BlogListItem>
          <BlogSubMiniHeader>
            {HE.section8.subH}
          </BlogSubMiniHeader>
          {HE.section8.p.map(p => <Paragraph>{p}</Paragraph>)}
        </BlogListItem>

        <BlogListItem>
          <BlogSubMiniHeader>
            {HE.section9.subH}
          </BlogSubMiniHeader>
          {HE.section9.p.map(p => <Paragraph>{p}</Paragraph>)}
        </BlogListItem>

      </BlogList>
    </Blog >
  )
}

export default PrinciplesOnDesigningSaasApp;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;